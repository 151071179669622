import ElementUI from 'element-ui';
import axios from 'axios'
export default {
    Img_Url: axios.defaults.baseURL + 'media/',
    CommImg_Url: "https://api.shangshangpiao.com/",
    /**
     * 提示框
     * @param {*} type  类型
     * @param {*} message  提示内容
     * @param {*} onClose  关闭回调
     * @param {*} duration 提示时间
     */
    ShowMsg: function (message = "", type = "success", onClose = "", duration = "1000") {
        ElementUI.Message({
            message: message,
            type: type,
            duration: duration,
            onClose: onClose
        });
    },
    /**
     * 根据响应结果 Code 提示框
     * @param {*} res 响应结果
     * @param {*} onClose 回调
     * @param {*} SuccerrShow 成功是否提示
     * @param {*} duration 
     */
    ResMsg: function (res, onClose = "", SuccerrShow = false, ErrorShow = false, duration = 1000) {
        console.log(res)
        let MsgType = res.code == 200 ? 'success' : 'error'
        if (SuccerrShow && res.code == 200) {
            return false
        }
        if (ErrorShow && res.code == 400) {
            return false
        }
        ElementUI.Message({
            message: res.msg,
            type: MsgType,
            duration: duration,
            onClose: onClose
        });
    },
    // Object 转 Array
    ObjToArray(obj) {
        var arr = Object.keys(obj);//arr=["one", "two"]
        var valArr = arr.map(function (i) { return obj[i] });//["I am 1", "I am 2"]
        return valArr
    },

    /*
 * 验证是否为数字
 */
    isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    /*
     * 是否为数组
     */
    , isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
    }

    /*
     * 是否空数组
     */
    , isArrayEmpty(val) {
        if (val && val instanceof Array && val.length > 0) {
            return false;
        } else {
            return true;
        }
    },
    ForMatTime(time,format) {
        let date = new Date(time).valueOf();
        date = new Date(parseInt(date));
        date = String(date)
        date = new Date(date.replace(/\-/g, '/'));
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        if (format == "Y-M-D") {
            return Y + M + D
        } else {
            return Y + M + D + h + m + s
        }
    }

}

