import { post, FileUpload } from './http'

const ParentCompany = p => post('ssp_admin/parent_company', p);
const ParentCompanyEdit = p => FileUpload('ssp_admin/parent_company_information_modification', p);
const get_parent_company = p => post('ssp_admin/get_parent_compa', p);
const ParentCompanyUpload = p => FileUpload('ssp_admin/wjsc', p);
const BlackListUpload = p => FileUpload('ssp_admin/blacklist_po', p);
const BankInfoUpload = p => FileUpload('ssp_admin/Bank_outlet_po', p);
const CashUpload = p => FileUpload('ssp_admin/settlement', p);
const GetFareList = p => post('ssp_admin/get_piaoxi', p);
const AddFare = p => post('ssp_admin/set_piaoxi', p);



export default
    {
        // 母公司列表
        ParentCompany,
        // 修改母公司
        ParentCompanyEdit,
        // 搜索母公司
        get_parent_company,
        // 母公司上传
        ParentCompanyUpload,
        // 黑名单上传
        BlackListUpload,
        // 银行行口
        BankInfoUpload,
        // 解付记录
        CashUpload,
        // 可贴现票系
        GetFareList,
        // 可贴现票系 添加
        AddFare
    }
