import Vue from 'vue'
import Vuex from 'vuex'


import VueCookies from 'vue-cookies'
Vue.use(Vuex)

export const state = {
    UserInfo: '',
    Token: JSON.parse(VueCookies.get('Token')),
    CommUuid:""
}

// 同步修改
export const mutations = {
    // 修改ToKen
    SET_TOKENFun(state, NewValue) {
        state.Token = NewValue;
    },
    // 修改用户信息
    SET_USERINFOFun(state, NewValue) {
        state.UserInfo = NewValue;
    },
    // 删除ToKen
    DEL_TOKENFUN(state){
        state.Token = ""
        VueCookies.remove('Token')
    },
    // 设置查看帖子的UUID
    Set_Comm_UuidFun(state,NewValue)
    {
        state.CommUuid = NewValue
    }
}

// 计算属性
export const getters = {

}

// 异步修改
export const actions = {
    SET_TOKEN(state,NewValue) {
        state.commit('SET_TOKENFun', NewValue)
        SaveCookie('Token',NewValue)
    },
    DEl_TOKEN(state){
        state.dispatch('DEL_TOKENFUN')
        DelCookie('Token')
    },
    set_comm_uuid(state,NewValue){
        state.commit('Set_Comm_UuidFun',NewValue)
    }
}


/**
 * 保存Cookie
 * @param {*} Key 
 * @param {*} Value 
 */
function SaveCookie(Key, Value) {
    // Cookies12小时有效期
    VueCookies.set(Key, JSON.stringify(Value), 60 * 60 * 12)
}

/**
 * 删除Cookie
 * @param {*} Key 
 * @param {*} Value 
 */
function DelCookie(Key) {
    // Cookies12小时有效期
    VueCookies.remove(Key)
}
