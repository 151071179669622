import {FileUpload} from './http'


// 用户相关
const AddComm = p =>  FileUpload('ssp_admin/random_user',p);

export default
{
    AddComm,
}
