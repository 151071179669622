import {post} from './http'


// 用户相关
const AdminLogin = p =>  post('ssp_admin/login',p);
// 用户列表
const UserList = p =>  post('ssp_admin/admin_user',p);
// 用户帖子
const UserComm = p =>  post('ssp_admin/admin_user_title',p);
// 可视化图表
const VisualTable = p =>  post('ssp_admin/admin_user_register',p);
const DealVisualTable = p =>  post('ssp_admin/admin_user_Merchant',p);
// 用户留言
const UserLeave = p =>  post('ssp_admin/admin_user_comment',p);

// 商户列表
const DealList = p =>  post('ssp_admin/get_Merchant_list',p);

// 查看商户认证信息
const CheckDeal = p =>  post('ssp_admin/get_information',p);

// 审核认证
const AuditI_Of= p =>  post('ssp_admin/set_Merchant_information_upload',p);
// 待审列表
const AwaitAudit = p =>  post('ssp_admin/get_Merchant_information_upload',p);






export default
{
    AdminLogin,
    UserList,
    UserComm,
    VisualTable,
    UserLeave,
    DealVisualTable,
    DealList,
    CheckDeal,
    AuditI_Of,
    AwaitAudit
}
