
const modulesFiles = require.context('./modules', true, /.js$/)
let modulesList = {}
modulesFiles.keys().reduce((modules, modulePath) => {
    if (modulePath != './http.js') {
        // const moduleName = modulePath.replace(/^.\/(.*)\.js/,'$1')
        const value = modulesFiles(modulePath)
        Object.assign(modulesList, value.default)
        // modules[moduleName] = value.default
        // return modules
    }
    return modules

}, {})

export default {
    ...modulesList
}

