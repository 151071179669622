import axios from 'axios'
import QS from 'qs'
import router from '@/router'
import ElementUI from 'element-ui';


import VueCookies from 'vue-cookies'
// 环境的切换

const AXIOSENV = 'production';
// 开发环境
if (AXIOSENV == 'development') {
    axios.defaults.baseURL = 'http://192.168.1.20:8000/';
}
// 生产环境
else if (AXIOSENV == 'production') {
    axios.defaults.baseURL = 'https://xcx.shangpiaoyi.cn/';
}

// 请求超时时间
axios.defaults.timeout = 10000;

// POST请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 先导入vuex,因为我们要使用到里面的状态对象
// vuex的路径根据自己的路径去写

import store from '@/state/store';
import {
    UserMethods,
} from "@/state/helpers";

// 请求拦截器
axios.interceptors.request.use(
    config => {
        const token = store.state.user.Token;

        token && (config.headers.token = token);

        return config;
    },
    error => {
        return Promise.error(error);
    })


// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
        if (response.status === 200) {
            if (response.data.code == 200) {
                return Promise.resolve(response);
            }

            if (response.data.admin_token == 402) {
                ElementUI.Message.error('登录信息已过期，请重新登录!');
       
                store.state.user.Token= ""
                VueCookies.remove('Token')
                router.push('/login');
                // 清空本地Token和Cookies
               
            }
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    error => {
        if (error.response.status) {
            // switch (error.response.status) {
            //     case 404:
            //         router.push({
            //             name: "Error-404",
            //         });
            //         break;

            //     case 500:
            //         router.push({
            //             name: "Error-500",
            //         });
            //         break;
            //     // 其他错误，直接抛出错误提示
            //     default:
            //         ElementUI.Message.error(error.response.data.message);
            // }
            return Promise.reject(error.response);
        }
    }
)


export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

export function FileUpload(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}