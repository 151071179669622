import {post} from './http'



const AdminList = p =>  post('ssp_admin/get_user',p);
const DelAdmin = p =>  post('ssp_admin/delete_user',p);
const AddAdmin = p =>  post('ssp_admin/set_username_root',p);
const EditAdmin = p =>  post('ssp_admin/update_user',p);



export default
{
    AdminList,
    DelAdmin,
    AddAdmin,
    EditAdmin
}
