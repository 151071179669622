import {post} from './http'


// 用户相关
const FeedbackList = p =>  post('ssp_admin/Feedback',p);

export default
{
    FeedbackList,
}
