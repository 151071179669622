import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import "@/assets/scss/app.scss";

// ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import api from '@/request/api'
import utils from "./utils/index"

window._API_ = api
window.utils = utils
window._store_ = store

import {UserMethods} from "@/state/helpers";

window.UserMethods = UserMethods
Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)


router.beforeEach((to, Form, next) => {

  const publicPages = ['/login'];
  const authpage = !publicPages.includes(to.path);

  const loggeduser = store.state.user.Token

  if ((loggeduser == null || !loggeduser) && authpage) {
    ElementUI.Message.warning('请先登录!');
    return next({
      path: '/login'
    });
  }

  if (loggeduser && !authpage) {
    // ElementUI.
    ElementUI.Message.warning('您已登录,请不要重复登录!');
    return next(Form.path);
  }

  // let RouterList = ['/login', '/', '/user/userlist', '/user/usercomm', '/comm/commlist', '/chat', '/calendar', '/ecommerce/products', '/ecommerce/product-detail', '/ecommerce/orders', '/ecommerce/customers', '/ecommerce/cart', '/ecommerce/checkout', '/ecommerce/shops', '/ecommerce/add-product', '/email/inbox', '/email/read', '/auth/login-1', '/auth/register-1', '/auth/lock-screen-1', '/auth/recoverpwd-1', '/apps/kanban-board', '/pages/starter', '/pages/maintenance', '/pages/coming-soon', '/pages/timeline', '/pages/faqs', '/pages/pricing', '/pages/error-404', '/pages/error-500', '/icons/font-awesome', '/icons/dripicons', '/icons/material-design', '/icons/remix', '/ui/buttons', '/ui/alerts', '/ui/grid', '/ui/cards', '/ui/carousel', '/ui/dropdowns', '/ui/images', '/ui/modals', '/ui/rangeslider', '/ui/progressbar', '/ui/sweet-alert', '/ui/tabs-accordion', '/ui/typography', '/ui/video', '/ui/general', '/ui/lightbox', '/ui/notification', '/ui/rating', '/ui/session-timeout', '/form/elements', '/form/validation', '/form/advanced', '/form/editor', '/form/uploads', '/form/wizard', '/form/mask', '/tables/basic', '/tables/advanced', '/charts/apex', '/charts/chartjs', '/charts/chartist', '/charts/echart', '/maps/google', '/maps/leaflet','/pages/errorpage','/feedbacks']

  // let PageError = !RouterList.includes(to.path)

  // if (PageError) {
  //   router.push({
  //     name: "ErrorPage",
  //   });
  // }

  return next()




})

new Vue({
  router,
  store,
  i18n,
  utils,
  render: h => h(App)
}).$mount('#app')
