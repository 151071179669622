import {post} from './http'


// 用户相关
const GetBillDiscount = p =>  post('ssp_admin/get_par_value',p);
const GetBillManage = p =>  post('ssp_admin/get_par_value_fast_melting',p);

export default
{
    GetBillDiscount,
    GetBillManage
}
